<template>
    <div>
        <page-header
            :title="$t('pages.closeAccount.permanentClose.title')"
            data-testid="close-account-notice-header"
            show-back
            @onClose="$router.go(-1)"
        />
        <div class="d-flex flex-column align-content-stretch flex-wrap mx-4">
            <div data-testid="close-account-notice-text-1">
                {{ $t('pages.closeAccount.permanentClose.subText1') }}
            </div>
            <div
                class="mt-3"
                data-testid="close-account-notice-text-2"
            >
                {{ $t('pages.closeAccount.permanentClose.subText2') }}
            </div>
            <base-button
                button-classes="btn btn-primary mt-4"
                data-testid="close-account-notice-continue-button"
                @click="handleClickButton"
            >
                Continue
            </base-button>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'
    import BaseButton from '@/components/base/BaseButton'
    import { mapGetters } from 'vuex'
    import { RouteNames } from '@/routes/router'
    import { AvenAccountStanding } from '@/store'
    import { logger } from '@/utils/logger'

    export default {
        name: 'CloseAccountPermanentlyNotice',
        mixins: [navigation],
        components: {
            PageHeader,
            BaseButton,
        },
        async mounted() {
            if (this.isAccountPendingCloseOrPayoff) {
                await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_PAYOFF })
                return
            }
            this.$logEvent('view_close_account_notice')
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters({
                accountStanding: 'avenAccountStanding',
            }),
            isAccountPendingCloseOrPayoff: function () {
                return this.accountStanding === AvenAccountStanding.pendingClose || this.accountStanding === AvenAccountStanding.pendingPayoffThenLimitedMode
            },
        },
        watch: {
            isAccountPendingCloseOrPayoff: async function (newValue, oldValue) {
                if (newValue) {
                    logger.info(`isAccountPendingCloseOrPayoff changed from ${oldValue} -> ${newValue}`)
                    await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_PAYOFF })
                    return
                }
            },
        },
        methods: {
            handleClickButton: async function () {
                this.$logEvent('click_close_account_notice_continue')
                await this.$router.push({ name: RouteNames.CLOSE_ACCOUNT_PAYOFF })
            },
        },
    }
</script>
